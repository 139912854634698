var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('l-map', {
    ref: _vm.mapRef,
    style: _vm.style,
    attrs: {
      "center": _vm.center,
      "bounds": _vm.mapBounds
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _vm._l(_vm.markers, function (marker, idx) {
    return _c('l-marker', {
      key: idx,
      attrs: {
        "lat-lng": marker,
        "icon": _vm.icon
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }